<template>
  <a
    @click="openSearchMenu"
    class="nav-link casino-btn casino-btn-default csn-nav-btn csn-nav-btn-search"
  >
    <span class="casino-icon casino-icon-search"></span>
  </a>
</template>
<script>
import { mapMutations } from 'vuex'
import { Module, TOGGLE_SEARCH_MENU } from '@/constants'

export default {
  name: 'NavbarSearchButton',
  methods: {
    ...mapMutations(Module.MAIN, [TOGGLE_SEARCH_MENU]),
    openSearchMenu() {
      this.TOGGLE_SEARCH_MENU(true)
    },
  },
}
</script>
